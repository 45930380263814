@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./images/dj.jpg");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  background-image: url("./images/dj.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.dj {
  background-size: fill;
  width: auto;
  height: auto;
  background-repeat: no-repeat;
}

.dj2 {
  background-color: black;
  background-size: cover;
  width: auto;
  height: auto;
  background-repeat: no-repeat;
}

/* HEADER */
.header-item:hover {
  background-color: #de005a;
}

/* HERO */
.hero {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  padding: 10% 35%;
}

/* ABOUT */
.about {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  padding: 10% 10%;
}

.about img {
  height: 200px;
  min-width: 200px;
}

/*  REGISTER  */

.form {
  display: flex-column;
  border: 2px solid red;
  border-radius: 5%;
  margin: 2% 20% 0;
  background-color: #1d1836;
  padding-top: 20px;
}

.inp {
  display: flex;
  justify-content: center;
  padding: 10px;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(0, 0, 0, 1);
  z-index: 900;
}

::-webkit-scrollbar-thumb {
  z-index: 900;
  background: #04207d;
  background: -webkit-linear-gradient(to bottom, #04207d, #04207d);
  background: linear-gradient(to bottom, #d6bd79, #04207d);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  z-index: 900;
  background: var(--color-golden);
  background: -webkit-linear-gradient(to bottom, #04207d, var(--color-golden));
  background: linear-gradient(to bottom, #04207d, var(--color-golden));
  border-radius: 6px;
}

:root {
  --font-base: "Cormorant Upright", serif;
  --font-alt: "Open Sans", sans-serif;

  --color-golden: #dcca87;
  --color-black: #0c0c0c;
  --color-gray: #545454;
  --color-crimson: #f5efdb;
  --color-grey: #aaaaaa;
  --color-white: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
