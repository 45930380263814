.app__guide {
  position: relative;
  margin: 1.5rem 0;
  padding: 1.5rem 1.5rem;
}

.guidelines-section {
  padding-bottom: 5%;
}

#guidelines {
  background: #000000;
  background: -webkit-linear-gradient(to right, #434343, #000000);
  background: linear-gradient(to right, #434343, #000000);
}

.app__guide-overlay {
  opacity: 0.9;
  padding: auto 2rem;
  box-shadow: 0px 0px 10px;
  border-radius: 10px;
}

.app__guide-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.app__guide-content {
  width: 100%;
  z-index: 5;
  padding: 0 20%;
}

#guide__p {
  font-weight: 700;
  margin: 10px;
  text-align: left;
  color: whitesmoke;
}

.app__guide-content_guidelines {
  text-align: center;
}

.app__guide-content_guidelines p {
  margin: 2rem 0;
  color: rgb(208, 203, 203);
}

/* HOVER EFFECT CSS */

.card {
  position: relative;
  width: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 10px 32px 32px;
  overflow: hidden;
  border-radius: 10px;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  color: #e8e8e8;
}

.card .para {
  line-height: 1.5;
  color: #e8e8e8;
  z-index: 3;
  text-align: left;
}

.card .para:hover {
  position: relative;
  z-index: 3;
}

.card::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(-45deg, #ff12ff 0%, #0bb6ff 100%);
  z-index: -10;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.card:hover::before {
  height: 100%;
}

.card:hover {
  box-shadow: none;
}
