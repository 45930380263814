#timeline {
  display: flex;
  flex-direction: column;
  background-color: var(--color-black);
}

#timeline {
  background: #000000;
  background: -webkit-linear-gradient(to right, #434343, #000000);
  background: linear-gradient(to right, #434343, #000000);
  padding-bottom: 80px;
}

.schedule__block {
  transform: translate3d(0, 0, 0);
  display: block;
  position: relative;
  width: 50vw;
  height: 120px;
  min-width: 50vw;
  color: #fff;
  overflow: hidden;
  transition: height 0.5s ease;
}
.schedule__block:before,
.schedule__block:after {
  transform: translate3d(0, 0, 0);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.schedule__block:after {
  background: rgba(0, 0, 0, 0.55);
  opacity: 1;
  transition: opacity 0.5s ease;
}
.schedule__block:before {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) -20%, black 95%);
  z-index: 1;
  opacity: 0;
  transform: translate3d(0, 0, 0) translateX(100%);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.schedule__block:hover {
  height: 300px !important;
}
.schedule__block:hover:after {
  opacity: 0;
}
.schedule__block:hover:before {
  opacity: 1;
  transform: translate3d(0, 0, 0) translateX(0);
  transition: opacity 1s ease, transform 1s ease 0.25s;
}
.schedule__block:hover .schedule__content {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.75s ease 0.5s;
}
.schedule__block:hover .schedule__bgImg {
  filter: grayscale(0);
}
.schedule__content {
  transform: translate3d(0, 0, 0) translateX(25px);
  position: relative;
  z-index: 1;
  text-align: center;
  margin: 1.2em auto;
  top: 50%;
  opacity: 0;
}
.schedule__content .schedule__date {
  text-transform: uppercase;
  color: var(--color-golden);
  font-size: 1.44rem;
  font-weight: normal;
}

.schedule__place {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.schedule__block:hover .schedule__place {
  top: 35%;
  transition: all 0.75s ease 0.5s;
}

.schedule__place .schedule__city {
  text-transform: uppercase;
  margin: 0;
  font-size: 1.728rem;
  /* line-height: 5.5; */
}
.schedule__bgImg {
  transform: translate3d(0, 0, 0);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
  transition: filter 0.5s ease;
  /* filter: grayscale(100%); */
}
.schedule__result {
  margin-top: 0.3rem;
  font-size: 1.3rem;
  font-weight: normal;
}
.schedule__result span {
  text-transform: uppercase;
}
