.flip-card {
  background-color: transparent;
  width: 245px;
  height: 270px;
  perspective: 1000px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 5px;
  border-radius: 2em;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #240d29;
  border: 4px solid #c143da;
}

.profile-image {
  background-color: transparent;
  border: none;
  margin-top: 15px;
  border-radius: 5em;
  width: 120px;
  height: 120px;
  margin-left: 50px;
}

.pfp {
  border-radius: 35em;
  fill: #c143da;
}

.name {
  margin-top: 15px;
  font-size: 27px;
  color: #c143da;
  font-weight: bold;
}

.flip-card-back {
  background-color: #240d29;
  border: 4px solid #c143da;
  transform: rotateY(180deg);
  padding: 11px;
}

.details {
  text-align: left;
  font-size: larger;
  color: #c143da;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* padding-left: 8%; */
}

.socialbar {
  background-color: transparent;
  border-radius: 3em;
  width: 90%;
  padding: 14px;
  margin-top: 11px;
  margin-left: 10px;
  word-spacing: 24px;
  color: white;
}

.socialbar svg {
  transition: 0.4s;
  width: 20px;
  height: 20px;
  color: #c143da;
}

.socialbar svg:hover {
  color: white;
}

.flip-card:hover .flip-card-inner {
  cursor: pointer;
  transform: rotateY(180deg);
}
