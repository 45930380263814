@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&family=Stylish&display=swap");

#about {
  background: #000000;
  background: -webkit-linear-gradient(to right, #434343, #000000);
  background: linear-gradient(to right, #434343, #000000);
}

.app__aboutus {
  position: relative;
  margin: auto;
  padding: 4rem 3rem;
  text-align: center;
}

.app__aboutus-content_about {
  padding: 1rem 2rem;
  box-shadow: 0px 0px 10px;
  border-radius: 10px;
}

#aboutus__p {
  font-weight: 700;
  line-height: 1.6;
}

.app__aboutus__inner {
  margin-top: 2rem;
}

.app__aboutus-content_about {
  text-align: center;
}

.app__aboutus-content_about p {
  margin: 2rem 0;
  color: rgb(208, 203, 203);
}

#aboutUs__image {
  margin: 0;
  height: 50vh;
}

@media (max-width: 1400px) {
  #aboutUs__image {
    display: none;
  }

  .app__aboutus {
    padding: 2rem 4rem;
  }
}

/*  */

.section__padding {
  /* padding: 4rem 6rem; */
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app__wrapper_info {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.app__wrapper_img {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
}

.app__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.app__wrapper_img img {
  width: 70%;
}

.custom__button {
  background-color: var(--color-crimson);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
  padding: 0.5rem 1.5rem;
  border-radius: 1px;
  border: none;
  outline: none;
  cursor: pointer;
}

.p__cormorant {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-feature-settings: "tnum" on, "lnum" on;
  line-height: 29.9px;
  font-size: 23px;
}

.p__opensans {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 28px;
  font-size: 16px;
}

.headtext__cormorant {
  /* font-family: var(--font-base); */
  font-family: "Stylish", sans-serif;
  color: white;
  font-size: 64px;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;

  text-shadow: 0 0 15px whitesmoke;
}

.spoon__img {
  width: 45px;
}

@media screen and (min-width: 2000px) {
  .custom__button,
  .p__cormorant {
    font-size: 37px;
    line-height: 67px;
  }

  .p__opensans {
    font-size: 30px;
    line-height: 50px;
  }

  .headtext__cormorant {
    font-size: 150px;
    line-height: 210px;
  }

  .spoon__img {
    width: 80px;
  }
}

@media screen and (max-width: 1150px) {
  .app__wrapper {
    flex-direction: column;
  }

  .app__wrapper_img {
    margin: 5rem 0 0 0;
  }

  .app__wrapper_img-reverse {
    margin: 0 0 5rem 0;
  }

  .app__wrapper_img img {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .section__padding {
    padding: 4rem;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .p__cormorant {
    font-size: 21px;
  }

  .p__opensans {
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  .p__opensans {
    font-size: 12px;
  }

  .p__cormorant {
    font-size: 19px;
  }

  .headtext__cormorant {
    font-size: 45px;
    line-height: 70px;
  }
}
