@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap");

@font-face {
  font-family: homeTitle;
  src: url("../../webfonts/NOISEWARE.ttf");
}

@font-face {
  font-family: fontJunoon;
  src: url("../../webfonts/NOISEWARE.ttf");
}

@font-face {
  font-family: fontJunoon1;
  src: url("../../webfonts/NOISEWARE.ttf");
}

@font-face {
  font-family: fontJunoon2;
  src: url("../../webfonts/NOISEWARE.ttf");
}

@font-face {
  font-family: fontJunoon3;
  src: url("../../webfonts/NOISEWARE.ttf");
}

.app__header {
  background-color: rgba(0, 0, 0, 0.4);
  min-height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;
  /* background-image: url("../../images/dj.jpg"); */
}

.app__header-h1 {
  /* font-family: var(--font-base); */
  font-family: fontJunoon3;
  color: var(--color-golden);
  font-weight: 600;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-size: 7rem;
  /* text-shadow: 0 0 50px 20px rgba(0, 0, 0, 0.5); */
}

.app__header-img img {
  width: 80%;
}

@media screen and (max-width: 1150px) {
  .app__header-h1 {
    font-size: 90px;
  }
}

@media screen and (max-width: 730px) {
  .app__header-h1 {
    font-size: 80px;
  }
}

@media screen and (max-width: 650px) {
  .app__header-h1 {
    font-size: 80px;
    line-height: 87px;
  }
}

@media screen and (max-width: 480px) {
  .app__header-h1 {
    font-size: 70px;
    line-height: 70px;
  }
}

@media screen and (max-width: 410px) {
  .app__header-h1 {
    font-size: 4rem;
    line-height: 50px;
  }
}

@media screen and (max-width: 385px) {
  .app__header-h1 {
    font-size: 3.5rem;
    line-height: 50px;
  }
}

@media screen and (max-width: 340px) {
  .app__header-h1 {
    font-size: 4rem;
    line-height: 50px;
  }
}
