.app__contact {
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  padding-bottom: 20px;
}

.contact-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 20px;
}

.our_team {
  border-radius: 2%;
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2),
    0 6px 20px 0 rgba(255, 255, 255, 0.19);
  padding: 50px 0;
  margin: 10vh auto;
  max-width: 270px;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.picture_contact {
  display: inline-block;
  height: 12vw;
  width: 12vw;
  margin-bottom: 7vh;
  z-index: 1;
  position: relative;
}

.picture_contact::before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* background-color: var(--color-golden); */
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}

.our_team:hover .picture_contact::before {
  height: 0%;
}

.img_contact {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: all 0.9s ease 0s;
  box-shadow: 0 0 0 14px rgba(247, 245, 236, 0.1);
  transform: scale(1.1);
}

.our_team:hover .img_contact {
  box-shadow: 0 0 0 0 rgba(247, 245, 236, 0.1);
  transform: scale(1.3);
}

.team_content {
  margin: 0;
  padding: 0 0.8vw;
}

.h4_contact {
  display: block;
  font-size: 16px;
  color: #919294;
  text-transform: capitalize;
}

.ul_contact {
  width: 100%;
  padding: 0;
  margin: 0;
  /* background-color: var(--color-golden); */
  position: absolute;
  bottom: -100px;
  left: 0;
  transition: all 0.5s ease 0s;
}

.our_team:hover .ul_contact {
  bottom: 0;
}

.li_contact {
  display: inline-block;
}

.icon__links-contact {
  display: block;
  padding: 10px;
  font-size: 17px;
  color: var(--color-black);
  transition: all 0.3s ease 0s;
  text-decoration: none;
}

.icon__links-contact:hover {
  color: var(--color-golden);
  background-color: #848484;
}
.h3_contact {
  font-size: 22px;
  margin-bottom: 5px;
  color: whitesmoke;
  text-transform: uppercase;
}

@media (max-width: 930px) {
  .picture_contact {
    height: 14vw;
    width: 14vw;
  }
}

@media (max-width: 576px) {
  .our_team {
    margin: 2vh 1vh;
    padding: 5vh 0 2vh;
  }

  .picture_contact {
    height: 14vw;
    width: 14vw;
    margin-bottom: 1vh;
  }

  .img_contact {
    box-shadow: 0 0 0 0 rgba(247, 245, 236, 0.1);
    transform: scale(1.5);
  }

  .h3_contact {
    margin-top: 20px;
  }

  .our_team:hover .img_contact {
    transform: scale(1.6);
  }
}
